import React from 'react'
import "./NewHome.css"
// import NewHeader from './Components/NewHeader/NewHeader'
import NewBanner from './Components/NewBanner/NewBanner'
import NewQuickShop from './Components/NewQuickShop/NewQuickShop'
import NewImages from './Components/NewImages/NewImages'
import NewCards from './Components/NewCards/NewCards'
import Navbar from "../Navbar";
import Footer from '../Footer'
// import PopupComponent from '../../Modal/PopupComponent'
function NewHome() {
  return (
    <div>
      {/* <PopupComponent/> */}
      <div className="whats_icon">
          <a
            href="https://wa.me/923209015555"
 target="_blank"

          >
            <i class="fa-brands fa-whatsapp"></i>
          </a>
        </div>
      <Navbar />
      {/* <NewHeader/> */}
      <NewBanner/>
      <NewQuickShop/>
      <NewImages/>
      <NewCards/>
      <Footer/>
    </div>
  )
}

export default NewHome
